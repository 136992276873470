<template>
  <div class="flex flex-column">
    <Dialog :closable="false" v-model:visible="loading" modal>
      <ProgressSpinner />
    </Dialog>
    <Toolbar
      class="p-toolbar sm:flex justify-content-between gap-5 lg:justify-content-between"
    >
      <template #start>
        <div class="flex">
          <a class="flex align-items-center" href="">
            <img
              src="../assets/logoCREPH.png"
              alt="LogoCREPH"
              class="h-4rem mr-5"
            />
          </a>
          <Button @Click="back" label="Retornar" text plain />
        </div>
      </template>
      <template #end>
        <div>
          <LogoutButton />
        </div>
      </template>
    </Toolbar>
    <h2 class="text-center mb-0 mx-4" v-if="selectedPmaName">
      Fichas Médicas no {{ selectedPmaName }}
    </h2>
    <div
      v-if="
        isVisualizadorPMA ||
        isMedico ||
        isEnfermeiro ||
        isTecEnfermagem ||
        isOperadorCentral ||
        isOutro
      "
    >
      <SituacaoAtualPMA :fichasMedicas="filteredFichasMedicas" />
    </div>
    <div class="card">
      <div class="flex gap-1 w-full">
        <div class="flex flex-column align-items-center">
          <Calendar
            v-model="selectedDates"
            placeholder="Selecione as datas"
            selectionMode="multiple"
            @change="applyFilters"
            dateFormat="dd/mm/yy"
            showIcon
          />
          <span>Selecione as datas</span>
        </div>
        <div class="flex flex-column align-items-center">
          <InputText
            class="w-7rem"
            v-model="startTime"
            type="time"
            @input="applyFilters"
          />
          <span>Hora Inicial</span>
        </div>
        <div class="flex flex-column align-items-center">
          <InputText
            class="w-7rem"
            v-model="endTime"
            type="time"
            @input="applyFilters"
          />
          <span>Hora Final</span>
        </div>
      </div>
      <div class="flex mt-3">
        <Button class="w-full" label="Filtrar" @click="applyFilters" />
        <Button class="w-full" label="Limpar Filtro" @click="clearFilter" />
      </div>
    </div>

    <div class="card" v-if="!isVisualizadorPMA">
      <div class="flex flex-column mb-4">
        <InputText
          v-model="searchTerm"
          placeholder="Buscar por nome do paciente"
        />
      </div>
      <div class="flex flex-column justify-content-center">
        <DataTable
          :value="filteredFichasMedicas"
          dataKey="id"
          :expandedRows="expandedRows"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
        >
          <template #header>
            <div class="flex flex-wrap justify-content-start gap-1">
              <Button
                text
                icon="pi pi-plus"
                label="Expandir Tudo"
                @click="expandAll"
                class="m-0 mb-3 w-7rem"
              />
              <Button
                text
                icon="pi pi-minus"
                label="Recolher Tudo"
                @click="collapseAll"
                class="m-0 mb-3 w-7rem"
              />
              <Button
                @click="toggleDiv"
                class="m-0 h-full w-6rem"
                label="Criar Ficha"
              />
              <div v-if="isDivVisible" class="expandable-div">
                <CriarFichaMedica />
                <Button
                  @click="cancel"
                  class="w-5rem h-3rem ml-3 mb-3"
                  label="Cancelar"
                />
              </div>
            </div>
            <div class="flex justify-content-between">
              <Button
                icon="pi pi-file-excel"
                label="Exportação Resumida"
                @click="exportResumida()"
                class="m-0 mb-4 w-6"
              />
              <Button
                icon="pi pi-file-excel"
                label="Exportação Completa"
                @click="exportCompleta()"
                class="m-0 mb-4 w-6"
              />
            </div>
          </template>
          <Column expander class="w-1" :header="'expandir'" />
          <Column
            :field="'hora_entrada'"
            :header="'Hora de Entrada'"
            :sortable="true"
            class="text-center w-1"
          ></Column>
          <Column
            :field="'data'"
            :header="'Data do Atendimento'"
            :sortable="true"
            class="text-center w-1"
          >
            <template #body="slotProps">
              {{ formatarData(slotProps.data.data) }}
            </template>
          </Column>
          <Column
            :field="'nome_paciente'"
            :header="'Nome do Paciente'"
            :sortable="true"
            :body="expandableBodyTemplate"
            class="text-center w-2"
          ></Column>
          <Column :header="'Idade'" :sortable="true" class="text-center">
            <template #body="slotProps">
              {{ calcularIdade(slotProps.data.nascimento) }}
            </template>
          </Column>
          <Column
            :field="'sexo'"
            :header="'Sexo'"
            :sortable="true"
            class="text-center w-1"
          ></Column>
          <Column
            :field="'queixas'"
            :header="'Queixas'"
            :sortable="true"
            class="text-center"
          ></Column>
          <Column class="w-1" :header="'Status'">
            <template #body="slotProps">
              <div
                :class="{
                  'status-em-atendimento':
                    slotProps.data.liberado === 'Em atendimento',
                  'status-liberado': slotProps.data.liberado === 'Liberado',
                  'status-removido': slotProps.data.liberado === 'Removido',
                }"
                class="text-center"
              >
                {{ slotProps.data.liberado }}
              </div>
            </template>
          </Column>
          <Column :header="'Ações'" class="text-center w-1">
            <template #body="slotProps">
              <div class="flex flex-column align-items-center">
                <Button
                  expander
                  v-if="
                    isAdmin ||
                    isEnfermeiro ||
                    isMedico ||
                    isOperadorCentral ||
                    isTecEnfermagem
                  "
                  class="w-8"
                  label="Editar"
                  @click="editFicha(slotProps.data)"
                />
                <Button
                  v-if="isAdmin"
                  label="Excluir"
                  @click="excluirFicha(slotProps.data.id)"
                  class="w-8 bg-red-600 delete-button"
                />
                <Button
                  icon="pi pi-print"
                  class="w-8 mt-2"
                  @click="printFicha(slotProps.data)"
                />
              </div>
            </template>
          </Column>
          <template #expansion="slotProps">
            <h3 class="my-3 text-center">Detalhes do Paciente</h3>
            <table class="mb-2">
              <thead>
                <tr>
                  <th>Local</th>
                  <th>Data de Atendimento</th>
                  <th>Hora da Entrada</th>
                  <th>Hora da Saida</th>
                  <th>Nome</th>
                  <th>Sexo</th>
                  <th>Idade</th>
                  <th>Data de Nascimento</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ slotProps.data.local }}</td>
                  <td>{{ formatarData(slotProps.data.data) }}</td>
                  <td>{{ slotProps.data.hora_entrada }}</td>
                  <td>{{ slotProps.data.hora_saida }}</td>
                  <td>{{ slotProps.data.nome_paciente }}</td>
                  <td>{{ slotProps.data.sexo }}</td>
                  <td>{{ calcularIdade(slotProps.data.nascimento) }}</td>
                  <td>{{ formatarData(slotProps.data.nascimento) }}</td>
                </tr>
              </tbody>
            </table>
            <table class="mb-2">
              <thead>
                <tr>
                  <th>Acompanhante</th>
                  <th>Telefone</th>
                  <th>Queixas</th>
                  <th>Doenças</th>
                  <th>Hospitalização</th>
                  <th>Tratamento</th>
                  <th>Alergias</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ slotProps.data.acompanhante }}</td>
                  <td>{{ slotProps.data.telefone }}</td>
                  <td>{{ slotProps.data.queixas }}</td>
                  <td>{{ slotProps.data.doencas }}</td>
                  <td>{{ slotProps.data.hospitalizacao }}</td>
                  <td>{{ slotProps.data.tratamento }}</td>
                  <td>{{ slotProps.data.alergias }}</td>
                </tr>
              </tbody>
            </table>
            <table class="mb-2">
              <thead>
                <tr>
                  <th>P.A</th>
                  <th>FC</th>
                  <th>FR</th>
                  <th>Temperatura</th>
                  <th>SpO2</th>
                  <th>Dor</th>
                  <th>DX</th>
                  <th>Prescrição</th>
                  <th>Evolução</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ slotProps.data.pa }}</td>
                  <td>{{ slotProps.data.fc }}</td>
                  <td>{{ slotProps.data.fr }}</td>
                  <td>{{ slotProps.data.temperatura }}</td>
                  <td>{{ slotProps.data.sp02 }}</td>
                  <td>{{ slotProps.data.dor }}</td>
                  <td>{{ slotProps.data.dx }}</td>
                  <td>{{ slotProps.data.prescricao }}</td>
                  <td>{{ slotProps.data.evolucao }}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th class="text-center">Observação / Retorno:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ slotProps.data.observacao }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="
                (slotProps.data.avaliacao_funcional &&
                  slotProps.data.avaliacao_funcional !== 'null') ||
                (slotProps.data.avaliacao_trauma &&
                  slotProps.data.avaliacao_trauma !== 'null') ||
                slotProps.data.escala_glasgow ||
                slotProps.data.controle_acesso_dreno ||
                slotProps.data.evolucao_enfermagem ||
                slotProps.data.evolucao_medica
              "
            >
              <h3 class="my-3 text-center">Ficha de Remoção</h3>

              <!-- Tabela de Avaliação Funcional, Trauma e Escala de Glasgow -->
              <table
                v-if="
                  (slotProps.data.avaliacao_funcional &&
                    slotProps.data.avaliacao_funcional !== 'null') ||
                  (slotProps.data.avaliacao_trauma &&
                    slotProps.data.avaliacao_trauma !== 'null') ||
                  slotProps.data.escala_glasgow
                "
              >
                <thead>
                  <tr>
                    <th class="text-center">Avaliação Funcional</th>
                    <th class="text-center">Avaliação Trauma</th>
                    <th class="text-center">Escala de Glasgow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- Exibe Avaliação Funcional se não for nula ou string "null" -->
                    <td
                      v-if="
                        slotProps.data.avaliacao_funcional &&
                        slotProps.data.avaliacao_funcional !== 'null'
                      "
                    >
                      <div
                        v-for="(value, key) in JSON.parse(
                          slotProps.data.avaliacao_funcional
                        )"
                        :key="key"
                      >
                        <strong
                          ><a>{{ formatarChave(key) }}</a> :</strong
                        >
                        {{ value }}
                      </div>
                    </td>

                    <!-- Exibe Avaliação Trauma se não for nula ou string "null" -->
                    <td
                      v-if="
                        slotProps.data.avaliacao_trauma &&
                        slotProps.data.avaliacao_trauma !== 'null'
                      "
                    >
                      <div
                        class="flex"
                        v-for="(value, key) in JSON.parse(
                          slotProps.data.avaliacao_trauma
                        )"
                        :key="key"
                      >
                        <strong
                          ><a>{{ formatarChave(key) }}</a> :
                        </strong>
                        <div v-if="typeof value === 'object'">
                          <div
                            v-for="(subValue, subKey) in value"
                            :key="subKey"
                          >
                            {{ subKey }}:
                            {{
                              subValue === true
                                ? "Sim"
                                : subValue === false
                                ? "Não"
                                : subValue
                            }}
                          </div>
                        </div>
                        <div v-else>{{ value }}</div>
                      </div>
                    </td>

                    <!-- Exibe Escala de Glasgow se não for nula -->
                    <td v-if="slotProps.data.escala_glasgow">
                      <strong>Escala de Glasgow:</strong>
                      {{
                        JSON.parse(slotProps.data.escala_glasgow).totalPontos ||
                        "N/A"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Tabela de Controle de Acesso e Dreno -->
              <table v-if="slotProps.data.controle_acesso_dreno">
                <thead>
                  <tr>
                    <th class="text-center">Controle de Acesso e Dreno</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ slotProps.data.controle_acesso_dreno }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Tabela de Evolução Enfermagem -->
              <table v-if="slotProps.data.evolucao_enfermagem">
                <thead>
                  <tr>
                    <th class="text-center">Evolução Enfermagem</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ slotProps.data.evolucao_enfermagem }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Tabela de Evolução Médica -->
              <table v-if="slotProps.data.evolucao_medica">
                <thead>
                  <tr>
                    <th class="text-center">Evolução Médica</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ slotProps.data.evolucao_medica }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="editMode[slotProps.data.id]" ref="editForm">
              <h3>Editar Ficha Médica</h3>
              <form
                class="flex flex-wrap justify-content-between gap-2"
                @submit.prevent="updateFichaMedica(slotProps.data.id)"
              >
                <div class="flex">
                  <InputGroupAddon> Nome: </InputGroupAddon>
                  <InputText
                    id="nomePaciente"
                    v-model="editFormData.nome_paciente"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Sexo: </InputGroupAddon>
                  <InputText id="sexo" v-model="editFormData.sexo" required />
                </div>
                <div class="flex">
                  <InputGroupAddon>Nascimento</InputGroupAddon>
                  <InputText
                    type="text"
                    id="nascimento"
                    v-model="editFormData.nascimento"
                    maxlength="10"
                    @input="formatDateInput"
                    @blur="convertToBackendFormat"
                    placeholder="Data de Nascimento"
                    inputmode="numeric"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Queixas: </InputGroupAddon>
                  <InputText
                    id="queixas"
                    v-model="editFormData.queixas"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Doenças: </InputGroupAddon>
                  <InputText
                    id="queixas"
                    v-model="editFormData.doencas"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Hora da Saida: </InputGroupAddon>
                  <InputText
                    id="hora_saida"
                    v-model="editFormData.hora_saida"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Acompanhante: </InputGroupAddon>
                  <InputText
                    id="acompanhante"
                    v-model="editFormData.acompanhante"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Telefone: </InputGroupAddon>
                  <InputText
                    id="acompanhante"
                    v-model="editFormData.telefone"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Hospitalização: </InputGroupAddon>
                  <InputText
                    id="hospitalizacao"
                    v-model="editFormData.hospitalizacao"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Tratamento: </InputGroupAddon>
                  <InputText
                    id="tratamento"
                    v-model="editFormData.tratamento"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Alergias: </InputGroupAddon>
                  <InputText
                    id="alergias"
                    v-model="editFormData.alergias"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> PA: </InputGroupAddon>
                  <InputText id="pa" v-model="editFormData.pa" required />
                </div>
                <div class="flex">
                  <InputGroupAddon> FC: </InputGroupAddon>
                  <InputText id="fc" v-model="editFormData.fc" required />
                </div>
                <div class="flex">
                  <InputGroupAddon> FR: </InputGroupAddon>
                  <InputText id="fr" v-model="editFormData.fr" required />
                </div>
                <div class="flex">
                  <InputGroupAddon> Temperatura: </InputGroupAddon>
                  <InputText
                    id="temperatura"
                    v-model="editFormData.temperatura"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> SpO2: </InputGroupAddon>
                  <InputText id="sp02" v-model="editFormData.sp02" required />
                </div>
                <div class="flex">
                  <InputGroupAddon> Escala de Dor: </InputGroupAddon>
                  <InputText id="dor" v-model="editFormData.dor" required />
                </div>
                <div class="flex">
                  <InputGroupAddon> DX: </InputGroupAddon>
                  <InputText id="dx" v-model="editFormData.dx" required />
                </div>
                <div class="flex">
                  <InputGroupAddon> Prescrição: </InputGroupAddon>
                  <InputText
                    id="prescricao"
                    v-model="editFormData.prescricao"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Evolução: </InputGroupAddon>
                  <InputText
                    id="evolucao"
                    v-model="editFormData.evolucao"
                    required
                  />
                </div>
                <div class="flex">
                  <InputGroupAddon> Observação / Retorno: </InputGroupAddon>
                  <InputText id="evolucao" v-model="editFormData.observacao" />
                </div>
                <div class="flex">
                  <InputGroupAddon> Status: </InputGroupAddon>
                  <select id="status" v-model="editFormData.liberado" required>
                    <option value="Liberado">Liberado</option>
                    <option value="Removido">Removido</option>
                    <option value="Em atendimento">Em atendimento</option>
                  </select>
                </div>
                <div v-if="editMode[slotProps.data.id] && checked">
                  <AvaliacaoFuncional
                    :initialData="fichaRemocaoData[slotProps.data.id]"
                    @update:fichaRemocaoData="updateFichaRemocaoData"
                  />
                  <AvaliacaoTrauma
                    :initialData="fichaRemocaoData[slotProps.data.id]"
                    @update:avaliacaoTraumaData="updateFichaRemocaoData"
                  />
                  <EscalaGlasgow
                    :initialData="fichaRemocaoData[slotProps.data.id]"
                    @update:escalaGlasgowData="updateEscalaGlasgow"
                  />
                  <EvolucaoRemocao
                    :initialData="fichaRemocaoData[slotProps.data.id]"
                    @update:evolucaoRemocaoData="updateEvolucaoRemocaoData"
                  />
                </div>

                <div class="flex align-items-center">
                  <Checkbox
                    v-model="checked"
                    inputId="remocaoCheck"
                    binary
                    variant="filled"
                  />
                  <label for="remocaoCheck" class="ml-2"
                    >Ficha de Remoção</label
                  >
                </div>
                <Button class="w-2 m-0 m-0 my-3" label="Salvar" type="submit" />
                <Button
                  class="w-2 m-0 m-0 my-3"
                  label="Cancelar"
                  @click="cancelEdit(slotProps.data.id)"
                />
              </form>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, nextTick } from "vue";
import Button from "primevue/button";
import CriarFichaMedica from "./CriarFichaMedica.vue";
import Toolbar from "primevue/toolbar";
import LogoutButton from "./LogoutButton.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import InputGroupAddon from "primevue/inputgroupaddon";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import Calendar from "primevue/calendar";
import SituacaoAtualPMA from "./SituacaoAtualPMA.vue";
import AvaliacaoFuncional from "./AvaliacaoFuncional.vue";
import AvaliacaoTrauma from "./AvaliacaoTrauma.vue";
import EscalaGlasgow from "./escalaGlasgow.vue";
import Checkbox from "primevue/checkbox";
import { useRouter } from "vue-router";
import * as XLSX from "xlsx";
import EvolucaoRemocao from "./EvolucaoRemocao.vue";

const editForm = ref(null);
const router = useRouter();
const searchTerm = ref("");
const fichasMedicas = ref([]);
const expandedRows = ref({});
const editMode = ref({});
const editFormData = ref({});
const isDivVisible = ref(false);
const selectedPmaName = ref("");
const loading = ref(false);
const today = new Date();
const selectedDates = ref([today]);
const startTime = ref("");
const endTime = ref("");
const fichaRemocaoData = reactive({});

const formatDateInput = () => {
  let input = editFormData.value.nascimento.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

  if (input.length <= 2) {
    editFormData.value.nascimento = input;
  } else if (input.length <= 4) {
    editFormData.value.nascimento = input.slice(0, 2) + '-' + input.slice(2);
  } else {
    editFormData.value.nascimento = input.slice(0, 2) + '-' + input.slice(2, 4) + '-' + input.slice(4, 8);
  }
};
// Função para converter a data para o formato YYYY-MM-DD antes de enviar ao backend
const convertToBackendFormat = () => {
  const [day, month, year] = editFormData.value.nascimento.split('-');
  if (day && month && year && year.length === 4) {
    // Verifica se a data é válida antes de converter
    editFormData.value.nascimento = `${year}-${month}-${day}`; // Converte para o formato YYYY-MM-DD
  }
};
const isAdmin = ref(localStorage.getItem("isAdmin") === "true");
const isMedico = computed(() => localStorage.getItem("cargo") === "Médico");
const isEnfermeiro = computed(
  () => localStorage.getItem("cargo") === "Enfermeiro"
);
const isTecEnfermagem = computed(
  () => localStorage.getItem("cargo") === "TecEnfermagem"
);
const isVisualizadorPMA = computed(
  () => localStorage.getItem("cargo") === "Visualizador PMA"
);
const isOperadorCentral = computed(
  () => localStorage.getItem("cargo") === "Operador Central"
);
const isOutro = computed(() => localStorage.getItem("cargo") === "Outro");
const filteredFichasMedicas = computed(() => {
  return fichasMedicas.value.filter((ficha) => {
    // Filtragem por data
    if (selectedDates.value.length > 0) {
      const fichaDate = new Date(ficha.data);
      const isDateInRange = selectedDates.value.some(
        (selectedDate) =>
          formatarData(fichaDate) === formatarData(new Date(selectedDate))
      );
      if (!isDateInRange) {
        return false;
      }
    }
    // Filtragem por horário inicial e final
    if (startTime.value) {
      const fichaHoraEntrada = ficha.hora_entrada || "";
      if (fichaHoraEntrada < startTime.value) {
        return false;
      }
    }
    if (endTime.value) {
      const fichaHoraEntrada = ficha.hora_entrada || "";
      if (fichaHoraEntrada > endTime.value) {
        return false;
      }
    }
    // Filtragem por nome do paciente
    if (searchTerm.value) {
      const lowerSearchTerm = searchTerm.value.toLowerCase();
      const lowerName = ficha.nome_paciente.toLowerCase();
      if (!lowerName.includes(lowerSearchTerm)) {
        return false;
      }
    }
    return true;
  });
});

const formatarChave = (key) => {
  switch (key) {
    case "avaliacaoFuncional":
      return "Avaliação Funcional";
    case "consciencia":
      return "Consciência";
    case "constantes":
      return "Constantes";
    case "motricidadeSensibilidade":
      return "Motricidade e Sensibilidade";
    case "aspectoPele":
      return "Aspecto da Pele";
    case "ruidos":
      return "Ruídos";
    case "pulsoCarotideo":
      return "Pulso Carotídeo";
    case "pulsoRadial":
      return "Pulso Radial";
    case "observacoes":
      return "Observações";
    case "queixas":
      return "Queixas";
    default:
      return key.charAt(0).toUpperCase() + key.slice(1);
  }
};

const updateEscalaGlasgow = (data) => {
  // Inicializa o objeto escalaGlasgow se não estiver presente
  if (!fichaRemocaoData.escalaGlasgow) {
    fichaRemocaoData.escalaGlasgow = {
      aberturaOcular: null,
      respostaVerbal: null,
      melhorRespostaMotora: null,
      reatividadePupilar: null,
      totalPontos: 0,
    };
  }

  // Atualiza os campos de forma segura, preservando valores existentes
  fichaRemocaoData.escalaGlasgow.aberturaOcular =
    data.aberturaOcular ?? fichaRemocaoData.escalaGlasgow.aberturaOcular;

  fichaRemocaoData.escalaGlasgow.respostaVerbal =
    data.respostaVerbal ?? fichaRemocaoData.escalaGlasgow.respostaVerbal;

  fichaRemocaoData.escalaGlasgow.melhorRespostaMotora =
    data.melhorRespostaMotora ??
    fichaRemocaoData.escalaGlasgow.melhorRespostaMotora;

  fichaRemocaoData.escalaGlasgow.reatividadePupilar =
    data.reatividadePupilar ??
    fichaRemocaoData.escalaGlasgow.reatividadePupilar;

  fichaRemocaoData.escalaGlasgow.totalPontos =
    data.totalPontos ?? fichaRemocaoData.escalaGlasgow.totalPontos;

  // Confirma a atualização nos logs
};

const evolucaoRemocaoData = reactive({
  evolucaoEnfermagem: null,
  evolucaoMedica: null,
  controleAcessoDreno: null,
});
const updateEvolucaoRemocaoData = (data) => {
  Object.assign(evolucaoRemocaoData, data);
};
const checked = ref(false);

const updateFichaRemocaoData = (data) => {
  Object.assign(fichaRemocaoData, data);
};

const exportToExcel = (data, filename) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Fichas Médicas");
  XLSX.writeFile(wb, `${filename}.xlsx`);
};

const exportResumida = () => {
  const data = filteredFichasMedicas.value.map((ficha) => ({
    "Número da Ficha": ficha.id,
    "Hora de Entrada": ficha.hora_entrada,
    "Hora de Saída": ficha.hora_saida,
    Sexo: ficha.sexo,
    "Nome Completo": ficha.nome_paciente,
    Idade: calcularIdade(ficha.nascimento),
    "Data de Nascimento": formatarData(ficha.nascimento),
    Queixas: ficha.queixas,
    Prescrição: ficha.prescricao,
    Telefone: ficha.telefone,
    "Status Liberado": ficha.liberado,
  }));
  exportToExcel(data, "Fichas_Medicas_Resumidas");
};

const exportCompleta = () => {
  const data = filteredFichasMedicas.value.map((ficha) => ({
    "Número da Ficha": ficha.id,
    "Hora de Entrada": ficha.hora_entrada,
    "Hora de Saída": ficha.hora_saida,
    Sexo: ficha.sexo,
    "Nome Completo": ficha.nome_paciente,
    Idade: calcularIdade(ficha.nascimento),
    "Data de Nascimento": formatarData(ficha.nascimento),
    Queixas: ficha.queixas,
    Prescrição: ficha.prescricao,
    Telefone: ficha.telefone,
    "Status Liberado": ficha.liberado,
    Local: ficha.local,
    Acompanhante: ficha.acompanhante,
    Doenças: ficha.doencas,
    Hospitalização: ficha.hospitalizacao,
    Tratamento: ficha.tratamento,
    Alergias: ficha.alergias,
    "P.A": ficha.pa,
    FC: ficha.fc,
    FR: ficha.fr,
    Temperatura: ficha.temperatura,
    SpO2: ficha.sp02,
    Dor: ficha.dor,
    DX: ficha.dx,
    Evolução: ficha.evolucao,
    Observação: ficha.observacao,
  }));
  exportToExcel(data, "Fichas_Medicas_Completas");
};

const printFicha = (fichaMedica) => {
  router.push({ name: "PrintFicha", params: { id: fichaMedica.id } });
};

const toggleDiv = () => {
  isDivVisible.value = !isDivVisible.value;
};

const cancel = () => {
  isDivVisible.value = false;
};

const back = () => {
  if (localStorage.getItem("isAdmin") === "true") {
    router.push("/users-admin");
  } else {
    router.push("/panel");
  }
};
const resetFichaRemocaoData = (fichaId) => {
  fichaRemocaoData[fichaId] = {
    avaliacaoFuncional: "",
    consciencia: "",
    constantes: "",
    motricidadeSensibilidade: "",
    aspectoPele: "",
    ruidos: "",
    pulsoCarotideo: "",
    pulsoRadial: "",
    observacoes: "",
    queixas: "",
    pupilas: "",
    abdomen: "",
    av: {}, // Inicializado como objeto vazio para evitar referências entre fichas
    mucosas: "",
    escalaGlasgow: {}, // Inicializado corretamente
    evolucaoEnfermagem: "",
    evolucaoMedica: "",
    controleAcessoDreno: "",
  };
};
const fetchFichasMedicas = async () => {
  const pmaId = localStorage.getItem("pma_id");
  const date = today;

  if (!pmaId) {
    console.error("pma_id não encontrado no localStorage.");
    loading.value = false;
    return;
  }
  if (!date) {
    console.error("Data não selecionada.");
    loading.value = false;
    return;
  }

  let tentativasRestantes = 2;
  while (tentativasRestantes > 0) {
    try {
      loading.value = true;
      const response = await fetch(
        `https://backendcreph.onrender.com/api/fichas-medicas/${pmaId}?date=${date}`
      );
      const fichas = await response.json();

      fichas.forEach((ficha) => {
        // Inicializa os dados de remoção para cada ficha usando o ID
        if (!fichaRemocaoData[ficha.id]) {
          fichaRemocaoData[ficha.id] = {}; // Inicializa se não existir
        }
        resetFichaRemocaoData(ficha.id);

        // Verifica se os dados de avaliação funcional, trauma, e escala Glasgow existem antes de usar
        const avaliacaoFuncional = ficha.avaliacao_funcional
          ? JSON.parse(ficha.avaliacao_funcional)
          : null;
        const avaliacaoTrauma = ficha.avaliacao_trauma
          ? JSON.parse(ficha.avaliacao_trauma)
          : null;
        const escalaGlasgow = ficha.escala_glasgow
          ? JSON.parse(ficha.escala_glasgow)
          : null;

        // Apenas inclui os dados de remoção se eles não forem nulos
        if (avaliacaoFuncional || avaliacaoTrauma || escalaGlasgow) {
          Object.assign(fichaRemocaoData[ficha.id], {
            avaliacaoFuncional: avaliacaoFuncional
              ? avaliacaoFuncional.avaliacaoFuncional || null
              : null,
            consciencia: avaliacaoFuncional
              ? avaliacaoFuncional.consciencia || null
              : null,
            constantes: avaliacaoFuncional
              ? avaliacaoFuncional.constantes || null
              : null,
            motricidadeSensibilidade: avaliacaoFuncional
              ? avaliacaoFuncional.motricidadeSensibilidade || null
              : null,
            aspectoPele: avaliacaoFuncional
              ? avaliacaoFuncional.aspectoPele || null
              : null,
            ruidos: avaliacaoFuncional
              ? avaliacaoFuncional.ruidos || null
              : null,
            pulsoCarotideo: avaliacaoFuncional
              ? avaliacaoFuncional.pulsoCarotideo || null
              : null,
            pulsoRadial: avaliacaoFuncional
              ? avaliacaoFuncional.pulsoRadial || null
              : null,
            observacoes: avaliacaoFuncional
              ? avaliacaoFuncional.observacoes || null
              : null,
            queixas: avaliacaoFuncional
              ? avaliacaoFuncional.queixas || null
              : null,
            pupilas: avaliacaoTrauma ? avaliacaoTrauma.pupilas || null : null,
            abdomen: avaliacaoTrauma ? avaliacaoTrauma.abdomen || null : null,
            av: {
              ...(fichaRemocaoData[ficha.id].av || {}),
              ...(avaliacaoTrauma ? avaliacaoTrauma.av || {} : {}),
            },
            mucosas: avaliacaoTrauma ? avaliacaoTrauma.mucosas || null : null,
            aberturaOcular: escalaGlasgow
              ? escalaGlasgow.aberturaOcular || null
              : null,
            respostaVerbal: escalaGlasgow
              ? escalaGlasgow.respostaVerbal || null
              : null,
            melhorRespostaMotora: escalaGlasgow
              ? escalaGlasgow.melhorRespostaMotora || null
              : null,
            reatividadePupilar: escalaGlasgow
              ? escalaGlasgow.reatividadePupilar || null
              : null,
            totalPontos: escalaGlasgow ? escalaGlasgow.totalPontos || 0 : 0,
            evolucaoEnfermagem: ficha.evolucao_enfermagem || null,
            evolucaoMedica: ficha.evolucao_medica || null,
            controleAcessoDreno: ficha.controle_acesso_dreno || null,
          });
        }
      });

      fichasMedicas.value = fichas;
      applyFilters();
      setSelectedPmaName();
      loading.value = false;
      return;
    } catch (error) {
      console.error("Erro ao buscar fichas médicas:", error);
      loading.value = false;
      tentativasRestantes--;
      if (tentativasRestantes > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } else {
        alert("Erro ao buscar fichas médicas. Tente novamente mais tarde.");
        loading.value = false;
      }
    }
  }
};

const formatarData = (dataString) => {
  const data = new Date(dataString);
  data.setMinutes(data.getMinutes() + data.getTimezoneOffset());
  return data.toLocaleDateString("pt-BR");
};

const applyFilters = () => {
  let filtered = fichasMedicas.value;
  if (selectedDates.value.length > 0) {
    filtered = filtered.filter((ficha) => {
      const fichaDate = new Date(ficha.data);
      fichaDate.setMinutes(
        fichaDate.getMinutes() + fichaDate.getTimezoneOffset()
      );
      const isDateInRange = selectedDates.value.some(
        (date) =>
          fichaDate.getDate() === date.getDate() &&
          fichaDate.getMonth() === date.getMonth() &&
          fichaDate.getFullYear() === date.getFullYear()
      );
      const fichaTime = fichaDate.getHours() * 60 + fichaDate.getMinutes();
      const startTimeInMinutes = startTime.value
        ? parseInt(startTime.value.split(":")[0]) * 60 +
          parseInt(startTime.value.split(":")[1])
        : null;
      const endTimeInMinutes = endTime.value
        ? parseInt(endTime.value.split(":")[0]) * 60 +
          parseInt(endTime.value.split(":")[1])
        : null;
      const isTimeInRange =
        (!startTimeInMinutes || fichaTime >= startTimeInMinutes) &&
        (!endTimeInMinutes || fichaTime <= endTimeInMinutes);
      return isDateInRange && isTimeInRange;
    });
  }
  if (searchTerm.value) {
    filtered = filtered.filter((ficha) =>
      ficha.nome_paciente.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
  }
  filteredFichasMedicas.value = filtered;
};

const clearFilter = () => {
  selectedDates.value = [];
  startTime.value = "";
  endTime.value = "";
  searchTerm.value = "";
  applyFilters();
};

const excluirFicha = async (idFicha) => {
  if (confirm("Tem certeza que deseja excluir esta ficha médica?")) {
    let tentativasRestantes = 2;
    while (tentativasRestantes > 0) {
      try {
        loading.value = true;
        const response = await fetch(
          `https://backendcreph.onrender.com/api/ficha-medica/${idFicha}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          loading.value = false;
          alert("Ficha médica excluída com sucesso.");
          fichasMedicas.value = fichasMedicas.value.filter(
            (ficha) => ficha.id !== idFicha
          );
          expandedRows.value = {};
          return;
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
      } catch (error) {
        console.error("Erro ao excluir ficha médica:", error);
        tentativasRestantes--;
        if (tentativasRestantes > 0) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }
  }
};

const expandAll = () => {
  const expandedRowsObject = {};
  fichasMedicas.value.forEach((ficha) => {
    expandedRowsObject[ficha.id] = true;
  });
  expandedRows.value = expandedRowsObject;
};

const collapseAll = () => {
  expandedRows.value = {};
};

const onRowExpand = (event) => {
  console.log("Row expanded:", event.data);
};

const onRowCollapse = (event) => {
  console.log("Row collapsed:", event.data);
};

const editFicha = (ficha) => {
  if (editMode.value[ficha.id]) {
    cancelEdit(ficha.id);
  } else {
    editMode.value[ficha.id] = true;
    expandedRows.value[ficha.id] = true;
    editFormData.value = { ...ficha };
    editFormData.value.nascimento = new Date(ficha.nascimento)
      .toISOString()
      .split("T")[0];
    checked.value = ficha.liberado === "Removido";

    // Verifica se o formulário de edição está disponível e rola até ele
    nextTick(() => {
      if (editForm.value) {
        editForm.value.scrollIntoView({ behavior: "smooth" });
      }
    });
  }
};

const cancelEdit = (fichaId) => {
  editMode.value = { ...editMode.value, [fichaId]: false };
};

const updateFichaMedica = async (idFicha) => {
  let tentativasRestantes = 2;
  while (tentativasRestantes > 0) {
    try {
      loading.value = true;
      const requestData = { ...editFormData.value };
      // Inicialização das propriedades para evitar erros de undefined
      const avaliacaoFuncional = fichaRemocaoData.avaliacaoFuncional || null;
      const consciencia = fichaRemocaoData.consciencia || null;
      const constantes = fichaRemocaoData.constantes || null;
      const motricidadeSensibilidade =
        fichaRemocaoData.motricidadeSensibilidade || null;
      const aspectoPele = fichaRemocaoData.aspectoPele || null;
      const ruidos = fichaRemocaoData.ruidos || null;
      const pulsoCarotideo = fichaRemocaoData.pulsoCarotideo || null;
      const pulsoRadial = fichaRemocaoData.pulsoRadial || null;
      const observacoes = fichaRemocaoData.observacoes || null;
      const queixas = fichaRemocaoData.queixas || null;
      const pupilas = fichaRemocaoData.pupilas || null;
      const abdomen = fichaRemocaoData.abdomen || null;
      const av = fichaRemocaoData.av || null;
      const mucosas = fichaRemocaoData.mucosas || null;
      const escalaGlasgowData = fichaRemocaoData.escalaGlasgow || {};
      if (checked.value) {
        // Adiciona as informações da ficha de remoção ao requestData
        requestData.escala_glasgow = {
          aberturaOcular: escalaGlasgowData.aberturaOcular || null,
          respostaVerbal: escalaGlasgowData.respostaVerbal || null,
          melhorRespostaMotora: escalaGlasgowData.melhorRespostaMotora || null,
          reatividadePupilar: escalaGlasgowData.reatividadePupilar || null,
          totalPontos: escalaGlasgowData.totalPontos || 0,
        };
        requestData.avaliacao_funcional = {
          avaliacaoFuncional: avaliacaoFuncional,
          consciencia: consciencia,
          constantes: constantes,
          motricidadeSensibilidade: motricidadeSensibilidade,
          aspectoPele: aspectoPele,
          ruidos: ruidos,
          pulsoCarotideo: pulsoCarotideo,
          pulsoRadial: pulsoRadial,
          observacoes: observacoes,
          queixas: queixas,
        };
        requestData.evolucao_enfermagem =
          evolucaoRemocaoData.evolucaoEnfermagem;
        requestData.evolucao_medica = evolucaoRemocaoData.evolucaoMedica;
        requestData.controle_acesso_dreno =
          evolucaoRemocaoData.controleAcessoDreno;
        requestData.avaliacao_trauma = {
          pupilas: pupilas,
          abdomen: abdomen,
          av: av,
          mucosas: mucosas,
        };
      }

      const response = await fetch(
        `https://backendcreph.onrender.com/api/ficha-medica/${idFicha}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        loading.value = false;
        alert("Ficha médica atualizada com sucesso.");
        await fetchFichasMedicas();
        editMode.value = { ...editMode.value, [idFicha]: false };
        return;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      loading.value = false;
      console.error("Erro ao atualizar ficha médica:", error);
      tentativasRestantes--;
      if (tentativasRestantes > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  }
};

const calcularIdade = (nascimento) => {
  const hoje = new Date();
  const dataNascimento = new Date(nascimento);
  let idade = hoje.getFullYear() - dataNascimento.getFullYear();
  const mes = hoje.getMonth() - dataNascimento.getMonth();
  if (mes < 0 || (mes === 0 && hoje.getDate() < dataNascimento.getDate())) {
    idade--;
  }
  return idade;
};

const setSelectedPmaName = async () => {
  const pmaId = localStorage.getItem("pma_id");
  if (!pmaId) {
    console.error("pma_id não encontrado no localStorage.");
    return;
  }
  try {
    const response = await fetch(
      `https://backendcreph.onrender.com/api/pmas/${pmaId}`
    );
    const data = await response.json();
    selectedPmaName.value = data.nome_pma;
  } catch (error) {
    console.error("Erro ao buscar nome do PMA:", error);
  }
};
onMounted(() => {
  fetchFichasMedicas();
});
</script>

<style scoped>
a {
  color: black;
}
.delete-button {
  /* Estilos para o botão */
  color: white;
  background-color: rgb(207, 139, 139);
  border: 1px rgb(94, 32, 32);
  cursor: pointer;
}
.delete-button:hover {
  color: rgb(94, 32, 32);
  background-color: rgb(252, 221, 221);
}
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.status-em-atendimento {
  border-radius: 10px;
  padding: 5px;
  background-color: orange;
  color: white;
}
.status-liberado {
  border-radius: 10px;
  padding: 5px;
  background-color: green;
  color: white;
}
.status-removido {
  border-radius: 10px;
  padding: 5px;
  background-color: red;
  color: white;
}

@media (max-width: 576px) {
  button {
  }
}
</style>
